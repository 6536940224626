import type { SlotsType } from 'vue';
import type { DataItemModel } from 'o365-dataobject';
import type DataColumn from './DataGrid.DataColumn.ts';
import { InjectionKeys, logger } from 'o365-utils';
import { defineComponent, inject, watch, computed, onMounted, onUnmounted, useSlots, useAttrs, provide, h } from 'vue';

export const OColumn = defineComponent({
    name: 'OColumn',
    slots: Object as SlotsType<{
        default: { row: DataItemModel; column: DataColumn },
        editor: { row: DataItemModel; column: DataColumn },
        bulkupdateeditor: { row: DataItemModel; column: DataColumn },
        headertext: {},
        headerMenu: {},
        summary: { row: DataItemModel; column: DataColumn },
        aggregate: { row: DataItemModel; column: DataColumn },
        contextmenuTop: { row: DataItemModel; column: DataColumn },
        contextmenuBottom: { row: DataItemModel; column: DataColumn },
        overlay: {},
        filter: { row: DataItemModel; column: DataColumn },
    }>,
    props: {
        /**
     * Field from the data object
     */
        field: String, // NOT REACTIVE
        /**
         * String used in the header cell.
         * By default the dataobject field caption will be used or the colId
         * @translate
         */
        headerName: null, // REACTIVE
        /**
         * String used in the header title tooltip
         * @translate
         */
        headerTitle: String, // REACTIVE
        headerClass: null,
        /**
         * Optional unique id used for columns that are not part of dataobject fields
         */
        colId: String, // NOT REACTIVE
        /**
         * @ignore
         */
        column: String, // NOT REACTIVE

        /**
         * Overwrite copy and paste functions
         */
        overwriteCopy: Function,
        overwritePaste: Function,
        /**
         * The width of the column. *The default value depends on the field type
         */
        width: [String, Number], // REACTIVE
        /**
         * The mininum width of the column
         */
        minWidth: {
            type: [String, Number],
            default: 50
        }, // TODO: WATCHERS
        /**
         * Defines which side should the column be pinned
         * @values left, right
         */
        pinned: {
            type: String,
            default: null
        }, // REACTIVE
        /**
         * Optional custom filter component. Can be set to false to disable filter for the column
         */
        filter: {
            type: null,
            default: 'OFilter'
        }, // NOT REACTIVE
        /**
         * Filter field that will be used when provided instead of the data field
         */
        filterField: String,
        /** Sort by field that will be used when sorting instead of the data field */
        sortField: String,
        /**
         * Format used by default editors and when rendering values
         */
        format: String,
        cellStyle: null,
        cellClass: null,
        cellTitle: null, // REACTIVE
        /**
         * When set to `true` will hide column
         */
        hide: {
            type: Boolean,
            default: false
        },  // REACTIVE
        /**
         * When set to `true` will enable editing on the column
         */
        editable: {
            type: [Boolean, Function],
            default: false
        }, // REACTIVE
        /**
         * When set to `true` will enable sorting for the column
         */
        sortable: {
            type: Boolean,
            default: false
        }, // REACTIVE
        defaultSortDirection: {
            type: String,
            default: null
        },
        /**
         * When set to `false` will disable the column menu
         */
        disableMenu: {
            type: Boolean,
            default: false
        },
        // cellRenderer: null,
        // headerName: null,
        /**
         * Optional cell renderer component or function that recieves row and returns a value.
         * When provided overrides #default slot
         */
        cellrenderer: {
            type: null,
            default: null
        }, // NOT REACTIVE
        /**
         * Optional cell editor component.
         * When provided overrides #editor slot
         */
        celleditor: {
            type: null,
            default: null
        }, // NOT REACTIVE
        /**
         * Props that will be passed to the cell renderer component
         */
        cellrendererparams: null, // REACTIVE
        /**
         * Props that will be passed to the cell editor component
         */
        celleditorparams: null, // REACTIVE
        disableDistinct: {
            type: Boolean,
        },
        /**
         * When set to `true` will disable ordering for the column
         */
        suppressMovable: {
            type: Boolean,
            default: false
        },
        /**
         * When set to `true` will disable keyboard navigation for the column
         */
        suppressNavigable: {
            type: Boolean,
            default: false
        }, // REACTIVE
        /**
         * Disable mouse selection for the column throuhg mouse clicks and area drags. Will also disable drag selecting past this column
         */
        suppressSelection: {
            type: Boolean,
            default: false
        },
        /**
         * When set to `true` will enable bulk update for the column
         */
        bulkUpdate: {
            type: Boolean,
            default: false
        }, // REACTIVE
        /**
         * Optional function to have classes on cell depending on rendered row
         * @example row => row.Completed ? 'green' : 'red'
         */
        classFn: Function,
        /**
         * ???
         * TODO: Check what this is
         */
        bulkUpdateApplyFunction: Function,
        /**
         * Please use `flexWidth` instead
         * @deprecated
         */
        flexwidth: String,
        /**
         * The portion of unused space that should be added to this column
         */
        flexWidth: String,
        singleClickEdit: {
            type: Boolean,
            default: true
        }, // ???
        dblclickHandler: Function, // ???
        /** Show and edit dates in utc */
        utc: Boolean,
        aggregate: String, // TO BE REMOVED
        /**
         * Function called on copy that takes in a row and returns the coppied value.
         * Used when using custom renderers
         * @example row => row.Field
         */
        getCopyValue: Function,
        getPasteValue: Function,
        overwriteCopy: Function,
        overwritePaste: Function,
        editorClass: null, // TODO reactive

        /**
         * Aggregate column 
         */
        groupAggregate: String,
        /**
       * Enables advanced group by for this column based on path
       */
        groupPathField: String,
        /**
         *  Will be called for each of the path nodes created by group by path mode. Provides the path id, returned value will be used as the display.
         *  Can be async and should implement bulk loading mechanism.
         */
        groupPathReplacePlaceholder: Function,
        /**
         * 
         */
        groupAggregateFn: Function,
        summaryAggregate: [String, Function],
        summaryRound: [String, Number],
        summaryFormat: String,
        fallbackValue: String,
        resizable: null,
        disableResize: {
            type: Boolean,
            default: false,
        },
        // CardView only
        lines: Number,
        colspan: {
            type: Number,
            default: 12
        },
        /**
         * Add required styles for this column on new records. When dataobject is used the value will default to
         * !nullable && !hasDefault check
         */
        required: Boolean,
        /** Hide column from column chooser */
        hideFromChooser: Boolean,
        /**
         * Do not allow grouping on this column.
         * @nodeData
         */
        disableGrouping: Boolean,
        /**
         * Enable auto height for the coluumn.
         * WARNING: This setting is experimental and might be unstable
         * @ignore
         */
        autoHeight: Boolean,
        groupPathMode: Boolean,
        /**
         * Additional bound fields for this column. Used when rendering requried indicators or copying values
         */
        boundFields: Array,
        /**
         * Field type override
         */
        type: String,
    },
    setup(props, context) {
        const testingValue = props.colId ?? props.column ?? props.field;
        if (testingValue && ['o365_MultiSelect', 'o365_System', 'o365_Action',].includes(testingValue)) {
            import('o365-vue-services').then(alertModule => {
                alertModule.alert(`${testingValue} can't be used as a column id as it is system reserved`)
            });
            throw new TypeError(`System reserved column id ${testingValue}`);
        }
        const dataGridControl = inject(InjectionKeys.dataGridControlKey, null);

        const slots = useSlots();
        const attrs = useAttrs();

        const colId = computed(() => {
            return props.colId ?? props.field ?? props.column;
        });

        const dataColumn = computed(() => {
            return dataGridControl?.value?.dataColumns.getColumn(colId.value)
        });

        function changeColumnProperty(propName, value) {
            if (!dataColumn.value?.hasOwnProperty(propName)) { console.warn('Trying to set invalid prop for column ', propName, colId.value); return; }

            dataColumn.value[propName] = value;
        }

        watch(() => props.headerName, (newValue) => {
            changeColumnProperty('headerName', newValue);
        });

        const parentGroupId = inject(InjectionKeys.dataColumnGroupKey, null);
        onMounted(() => {
            if (dataGridControl?.value == null) {
                logger.warn('OColumn used outside of grid');
                return;
            }
            if (!dataGridControl?.value?.dataColumns?.initialColumnsMap?.[colId.value]) {
                if (dataGridControl.value?.columns?.addColumn != null) {
                    dataGridControl.value.columns.addColumn({
                        ...props,
                        cellClass: props.cellClass ?? attrs.class,
                        cellStyle: props.cellStyle ?? attrs.style,
                        slots: slots,
                    });
                    return;
                }

                const container = dataGridControl.value.container ?? document.getElementById(dataGridControl.value.id);
                const cols = Array.from(container.querySelector('.o365-column-definitions')?.querySelectorAll(`[o365-field]`) ?? []);
                if (dataGridControl.value.isCustomElement) {
                    container.querySelector('slot')?.assignedElements()?.forEach(customEl => {
                        customEl.shadowRoot?.querySelectorAll('[o365-field]')?.forEach(col => { cols.push(col); });
                    });
                }
                const colIndex = cols.findIndex(x => x.getAttribute('o365-field') === colId.value);
                if (colIndex === -1) { console.warn('Failed to mount column ', colId.value); return; }

                dataGridControl.value.asyncAddColumn({
                    ...props,
                    class: attrs.class,
                    cellRenderSlot: slots.default,
                    cellEditorSlot: slots.editor,
                    bulkUpdateEditorSlot: slots.batchupdateeditor,
                    headerTextSlot: slots.headertext,
                    filterSlot: slots.filter,
                    overlaySlot: slots.overlay,
                    summarySlot: slots.summary,
                    contextmenuTopSlot: slots.contextmenuTop,
                    contextmenuBottomSlot: slots.contextmenuBottom,
                    parentGroupId: parentGroupId
                }, colIndex + 2);
                // }, cols[colIndex + 1] ? colIndex + 2 : null);
            }
        });

        onUnmounted(() => {
            if (!dataGridControl?.value?.isBeingUnmounted) {

                dataGridControl.value.removeColumn(colId.value);
                delete dataGridControl.value.dataColumns.initialColumnsMap[colId.value];
            }
        });

        return { colId }
    },
    render() {
        return h('col', {
            'o365-field': this.colId
        });
    }
});


export const OColumnGroup = defineComponent({
    name: 'OColumnGroup',
    slots: Object as SlotsType<{
        default: {},
        headerMenu: {},
        headertext: {},
    }>,
    props: {
        children: Array,
        headerName: null,
        groupId: {
            type: String,
            required: true,
            default: (raw) => {
                return raw.headerName ?? window.crypto.randomUUID();
            }
        },
    },
    setup(props) {
        provide(InjectionKeys.dataColumnGroupKey, props.groupId);
        const slots = useSlots();
        const dataGridControl = inject(InjectionKeys.dataGridControlKey, null);

        const group = dataGridControl.value.dataColumns.getGroup(0, props.groupId);
        if (group == null) {
            // Group doesn't exist in data columns, append it
            dataGridControl.value.dataColumns.addGroup({
                ...props,
                children: props.children ?? [],
                headerTextSlot: slots.headertext,
                headerMenuSlot: slots.headerMenu
            });
        }
    },
    render() {
        return h('colgroup', {
            'o365-groupid': this.groupId
        }, this.$slots.default ? this.$slots.default() : undefined);
    }
});

export function parseColumnsFromVNodes(vnodes) {
    const parseProp = (vnode, name) => {
        if (vnode.dynamicProps?.includes(name)) { return vnode.props[name]; }
        else {
            let typeFunction = vnode.type.props[name];
            if (typeFunction != null && typeof typeFunction === 'object') {
                typeFunction = typeFunction.type;
            }
            if (!typeFunction) {
                return vnode.props[name];
            }

            if (Array.isArray(typeFunction)) {
                typeFunction = typeFunction[0];
                // return vnode.props[name];
            }
            switch (typeFunction?.name) {
                case null:
                    console.warn(`${vnode.type.name} ${name}="${vnode.props[name]}" property must be bound, use :${name} or v-bind:${name}`);
                    return vnode.props[name];
                case 'Boolean':
                    return vnode.props.hasOwnProperty(name) && (typeof vnode.props[name] === 'boolean' ? vnode.props[name] : vnode.props[name] !== 'false');
                case 'Function':
                    return vnode.props[name];
                default:
                    return typeFunction(vnode.props[name]);
            }
        }
    };

    const normalizePropName = (name) => {
        return name.split('-').map((word, index) => {
            if (index > 0) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            } else {
                return word;
            }
        }).join('');
    }

    // Extracting column nodes from fragment workaround.
    while (vnodes.length === 1 && typeof vnodes[0].type === 'symbol') {
        vnodes = vnodes[0].children;
    }


    const parsedColumns = vnodes.filter(vnode => vnode.type?.name === 'OColumn' || vnode.type?.name === 'OColumnGroup').flatMap(vnode => {
        switch (vnode.type.name) {
            case 'OColumn':
                const columnDef = {};
                for (let propName in vnode.props) {
                    let key = normalizePropName(propName);
                    columnDef[key] = parseProp(vnode, propName);
                }
                if (vnode.children?.default) { columnDef.cellRenderSlot = vnode.children.default; }
                if (vnode.children?.editor) { columnDef.cellEditorSlot = vnode.children.editor; }
                if (vnode.children?.bulkupdateeditor) { columnDef.bulkUpdateEditorSlot = vnode.children.bulkupdateeditor; }
                if (vnode.children?.headertext) { columnDef.headerTextSlot = vnode.children.headertext; }
                if (vnode.children?.aggregate) { columnDef.aggregateSlot = vnode.children.aggregate; }
                if (vnode.children?.filter) { columnDef.filterSlot = vnode.children.filter; }
                if (vnode.children?.overlay) { columnDef.overlaySlot = vnode.children.overlay; }
                if (vnode.children?.headerMenu) { columnDef.headerMenuSlot = vnode.children.headerMenu; }
                if (vnode.children?.summary) { columnDef.summarySlot = vnode.children.summary; }
                if (vnode.children?.contextmenuTop) { columnDef.contextmenuTopSlot = vnode.children.contextmenuTop; }
                if (vnode.children?.contextmenuBottom) { columnDef.contextmenuBottomSlot = vnode.children.contextmenuBottom; }
                return columnDef;
            case 'OColumnGroup':
                const groupDef = {};
                for (let propName in vnode.props) {
                    let key = normalizePropName(propName);
                    groupDef[key] = parseProp(vnode, propName);
                }
                if (vnode.children?.default) {
                    const childrenColumns = vnode.children.default();
                    const columnDefinitions = parseColumnsFromVNodes(childrenColumns);
                    groupDef.children = columnDefinitions;
                }
                if (vnode.children?.headertext) { groupDef.headerTextSlot = vnode.children.headertext; }
                if (groupDef.groupId == null) {
                    console.warn(`No groupId provided for column group ${groupDef.headerName}. Provide a unique groupId`)
                    groupDef.groupId = groupDef.headerName ?? window.crypto.randomUUID();
                }
                return groupDef;
        }
    });

    return parsedColumns;
}